<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-responsive min-height="350">
          <v-container>
            <transition-group
              tag="v-row"
              name="fade-transition"
            >
              <v-col
                v-for="project in computedProjects2"
                :key="project.img"
                cols="12"
                class="gallery-card"
                md="4"
              >
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card
                      :img="`/static/ze/${project.img}`"
                      class="white--text overflow-hidden"
                      dark
                      height="300"
                      hover
                      @click="overlay = project.img"
                    >
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                          <v-icon large>
                            mdi-magnify
                          </v-icon>
                        </v-overlay>
                      </v-fade-transition>
                      <v-slide-y-reverse-transition>
                        <v-footer
                          v-if="hover"
                          absolute
                          class="justify-center"
                          height="75"
                        >
                          <div class="heading">
                            {{ project.name }}
                          </div>
                        </v-footer>
                      </v-slide-y-reverse-transition>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </transition-group>
          </v-container>
        </v-responsive>

        <v-fade-transition mode="out-in">
          <v-overlay
            v-if="overlay"
            fixed
            opacity=".9"
          >
            <v-btn
              color="transparent"
              depressed
              fab
              fixed
              large
              right
              top
              @click="overlay = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>

            <v-img
              :src="`/static/ze/${overlay}`"
              width="800"
              max-width="90vw"
            />
          </v-overlay>
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="10"
        offset-md="1"
      >
        <base-subheading>
          <h1
            class="mt-5 mb-5"
            style="font-family: 'Skia', sans-serif;"
          >
            Kameralne osiedle Edmundowo Park
          </h1>
        </base-subheading>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>LOKALIZACJA</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          <strong>Edmundowo-Jankowice</strong>, gmina <strong>Tarnowo Podgórne</strong>. W pobliżu tereny zielone: las, <b>zespół pałacowo-parkowy Jankowice</b> (<a href="https://www.palacjankowice.pl/">www.palacjankowice.pl</a>). W odległości około 4km dostęp do pełnej infrastruktury Tarnowa Podgórnego (szkoły, przedszkola, sklepy, restauracje, baseny - Tarnowskie Termy, itp.). Kameralną lokalizację wyróżniają piękne krajobrazy, cisza i spokój oraz pełna infrastruktura drogowa.
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>NIERUCHOMOŚCI</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          Na terenie 6000 m² docelowo powstanie 6 budynków jednorodzinnych dwulokalowych (zabudowa bliźniacza). Inwestycja podzielona jest na 2 etapy. W pierwszym etapie (wrzesień 2022) do oddania w standardzie deweloperskim będą 3 <strong>budynki parterowe</strong> - łącznie <strong>6 lokali mieszkalnych</strong> o powierzchni <b>103,1 m²</b> każdy.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      category: null,
      overlay: false,
      projects: [
        {
          'name': 'osiedle Edmundowo Park',
          'img': 'ze-1.jpg',
          'categories': [3]
        },
        {
          'name': 'Parterowe domy o powierzchni 103,1m²',
          'img': 'ze-2.jpg',
          'categories': [3]
        },
        {
          'name': 'W sąsiedztwie pałacu w Jankowicach',
          'img': 'ze-3.jpg',
          'categories': [3]
        },
        {
          'name': 'Domy jednorodzinne dwulokalowe',
          'img': 'ze-4.jpg',
          'categories': [3]
        },
        {
          'name': 'Edmundowo - lepsza strona Jankowic',
          'img': 'ze-9.jpeg',
          'categories': [3]
        },
        {
          'name': 'Przestronne, parterowe lokale mieszkalne',
          'img': 'ze-10.jpeg',
          'categories': [3]
        },
        {
          'name': 'W sąsiedztwie parku i pałacu',
          'img': 'ze-rzut-1.png',
          'categories': [3]
        },
        {
          'name': 'Edmundowo - lepsza strona Jankowic',
          'img': 'ze-8.jpeg',
          'categories': [3]
        },
        {
          'name': 'Przestronne, parterowe lokale mieszkalne',
          'img': 'ze-6.jpg',
          'categories': [3]
        },
        {
          'name': 'Edmundowo Park',
          'img': 'ze-rzut-2.png',
          'categories': [3]
        },
        {
          'name': 'Edmundowo przy pałacu w Jankowicach',
          'img': 'ze-7.jpeg',
          'categories': [3]
        },
        {
          'name': 'Edmundowo - lepsza strona Jankowic',
          'img': 'ze-5.jpg',
          'categories': [3]
        },
        {
          'name': 'Edmundowo przy pałacu w Jankowicach',
          'img': 'ze-wew-1.jpeg',
          'categories': [3]
        },
        {
          'name': 'Edmundowo przy pałacu w Jankowicach',
          'img': 'ze-wew-2.jpeg',
          'categories': [3]
        },
        {
          'name': 'Edmundowo przy pałacu w Jankowicach',
          'img': 'ze-wew-3.jpeg',
          'categories': [3]
        }
      ]
    }),

    computed: {
      computedProjects2 () {
        return !this.category
          ? this.projects
          : this.projects.filter(p => p.categories.includes(this.category))
      }
    }
  }
</script>

<style scoped>
  .gallery-card {
    transform-origin: center center 0;
    transition: .3s cubic-bezier(.25,.8,.50,1);
  }

  .fade-transition-leave,
  .fade-transition-leave-active,
  .fade-transition-leave-to {
    display: none;
  }
</style>
